class ConstsClass {
    fieldTranslation = {
        forename: "Vorname",
        surname: "Nachname",
        phone: "Telefon",
        email: "E-Mail",
        pricenm: "Preis (kein Mitglied)",
        price: "Preis",
        olderthan: "Älter als",
        youngerthan: "Jünger als",
        street: "Straße",
        city: "Stadt",
        postalCode: "PLZ",
        route: "Straße",
        locality: "Ort",
        dateOfBirth: "Geburtsjahr",
        deathdate: "Sterbedatum",
        member: "Mitglied",
        memberStatus: "Mitglieds-Status",
        memberStatusNew: "Mitglieds-Status",
        memberStatusDescription: "Positionsbezeichnung",
        bank: "Banküberweisung", card: "Kreditkartenzahlung", sofort: "Sofortüberweisung", brig: "Bankbuchung", sepa_debit: "SEPA Lastschrift Einzug", voucher: "Gutschein",
        titlesAnte: "Titel vorangestellt",
        titlesPost: "Titel nachgestellt",
        titlesJob: "Berufsbezeichnung",
        svn: "Sozialvers.-Nr.",
        letterAddressation: "Briefanrede",
        companyName: "Firmenname",
        companyPosition: "Position in Firma",
        vatID: "UID-Nummer",
        companyRegNr: "FB-Nummer",
        phoneAdd: "Telefon 2",
        source: "Erfassungs-Quelle",
        sex: "Geschlecht",
        IDLegacy: "vorherige ID",
        zvrNr: "ZVR-Nr",
        name: "Name",
        gender: "Geschlecht",
        minparts: "Minimale Teilnehmer",
        maxparts: "Maximale Teilnehmer",
        priceInput: "Preis (€)",
        pricenmInput: "Preis kein Mitglied (€)",
        bookingStart: "Buchbar ab",
        bookingEnd: "Buchbar bis",
        showStart: "Anzeigen ab",
        showEnd: "Anzeigen bis",
        yearAllowedLeft: "Jahrgänge nach (JJJJ)",
        yearAllowedRight: "Jahrgänge vor (JJJJ)",
        membersOnly: "Mitgliedschaft erforderlich?",
        audience: "Zielgruppe",
        rolesAllowed: "Definierende Rollen",
        tags: "Tags",
        description: "Beschreibung",
        information: "Information",
        country: "Land",
        category: "Kategorie",
        weight: "Gewichtung",
        unit: "Einheit / Art",
        inverse: "Inverse Wertung",
        maxVal: "Maximaler Wert",
        maxPoints: "Maximale Punkte",
        timeShowstart: "Anzeigebeginn",
        status: "Status",
        eventStart: "Veranstaltungs-Start",
        eventEnd: "Veranstaltungs-Ende",
        allowOfficials: "Offizielle erlauben",
        type: "Art",
        discountedPrice: "Preis reduziert",
        onlyOfficials: "Nur Offizielle?",
        "prebookingEnd": "Frühbuchungs-Ende",
        recipient: "Name",
        recipientAddition: "zusätzlicher Name",
        streetNumber: "Hausnummer",
        streetNumberAdd: "Hausnummern-Zusatz",
        postcode: "PLZ",
        teamsRequired: "Anmeldung als Team",
        eventNoExt: "Veranstaltungs-Nr",
        isCompetitionPart: "Wettkampfteil",
        individualOrTeam: "Einzel oder Team",
        allowedSex: "erlaubtes Geschlecht",
        minAge: "ab Alter",
        maxAge: "bis Alter",
        registrantRequired: "Anmeldung nur für Meldeverantwortliche",
        allergies: "Nahrungsmittel-Unverträglichkeiten / Allergien",
        emergencyContact: "Notfall-Kontakt",
        apparatusNotesM: "Gerätebemerkung pro Altersklasse (männlich)",
        apparatusNotesW: "Gerätebemerkung pro Altersklasse (weiblich)",


        "website": "Website",
        "ooetb": "OÖTB Verein?",
        "oetb": "ÖTB Verein?",
        "est": "Gründungsdatum",
    };
    placeHolders = {
        svn: "1234010101",
        email: "max@mustermann.at",
        forename: "Max",
        surname: "Mustermann",
        phone: "+43 664 1234567",
        companyName: "Muster GmbH",
        vatID: "ATU12345678",
        companyRegNr: "123456a",
        name: "Allgemeiner Musterverein",
        website: "www.musterverein.at",
        zvrNr: 12456789,
        dateOfBirth: 1990,
        est: 1909,
    };
    weekdayNames = ["Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag", "Sonntag"];
    states = ["OÖ", "NÖ", "St", "Sa", "W", "B", "K", "T", "V"];
    statesFull = {OÖ: "Oberösterreich", NÖ: "Niederösterreich", St: "Steiermark", Sa: "Salzburg", W: "Wien", B: "Burgenland", K: "Kärnten", T: "Tirol", V: "Vorarlberg"};

    statesFlags = {
        T: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c3/AUT_Tirol_COA.svg/70px-AUT_Tirol_COA.svg.png",
        B: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/92/Burgenland_Wappen.svg/70px-Burgenland_Wappen.svg.png",
        K: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b5/Kaernten_CoA.svg/70px-Kaernten_CoA.svg.png",
        N: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/3f/Nieder%C3%B6sterreich_CoA.svg/70px-Nieder%C3%B6sterreich_CoA.svg.png",
        O: "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e1/Oberoesterreich_Wappen.svg/70px-Oberoesterreich_Wappen.svg.png",
        Sa: "https://upload.wikimedia.org/wikipedia/commons/thumb/d/de/Salzburg_Wappen.svg/70px-Salzburg_Wappen.svg.png",
        St: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/35/Steiermark_Wappen.svg/70px-Steiermark_Wappen.svg.png",
        V: "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e2/Vorarlberg_CoA.svg/70px-Vorarlberg_CoA.svg.png",
        W: "https://upload.wikimedia.org/wikipedia/commons/thumb/0/03/Wien_3_Wappen.svg/70px-Wien_3_Wappen.svg.png",
    };

    /*memberStates = {
        0: "Nichtmitglied",
        1: "ordentliches Mitglied",
        2: "außerordentliches Mitglied",
        3: "unterstützendes Mitglied",

        4: "befreites Mitglied",
        5: "Zeugwart Stellvertretung",
        6: "Zeugwart",

        7: "Sportliche Leitung Stellvertretung",
        8: "Sportliche Leitung",
        9: "Kassier Stellvertretung",
        10: "Kassier",
        11: "Schriftführer Stellvertretung",
        12: "Schriftführer",
        13: "Obfrau/Obmann Stellvertretung",
        14: "Obfrau/Obmann",

        40: "ruhendes Mitglied",
        41: "Trainer",

        //100: "Vorstand"
    };
    memberStatesShort = Object.keys(this.memberStates).reduce((obj, k) => ({...obj, [k]: this.memberStates[k].split(" ").map(u => u[0]).join(".")}), {});*/
    kontenKinds = {0: "Allgemeines Konto", 1: "Bank", 5: "Kundenkonto", 2: "Kontenklasse", 3: "Kostenträger"};
    buchSymbole = {AR: "Ausgangsrechnung", ER: "Eingangsrechnung", BB: "Bilanzbuchung", BU: "Buchungsanweisung", EB: "Eröffnungsbilanz", KA: "Kassa", SO: "Sonstige Belege", BK: "Bank", LG: "Lohn/Gehalt", UE: "Saldenübernahme"};
    sexMap = {male: "männlich", fem: "weiblich"};
    translate = (name) => this.fieldTranslation[name] || (name[0] || "").toUpperCase() + name.substr(1);


    money = new Intl.NumberFormat('de-AT', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2
    });
    moneyAlt = new Intl.NumberFormat('de-AT', {
        style: 'currency',
        currency: 'ATS',
        minimumFractionDigits: 2
    });

    constructor() {
        this.PHASE = process.env.NODE_ENV;
        this.f = this.f.bind(this);
        let f = this.f;

        this.API_PREFIX = "/api";//f("", "/api");
    }

    f(dev, prod, user_id = -1) {
        if (process.env.NODE_ENV === "development" || (user_id !== -1 && this.user_id_prod.indexOf(user_id) < 0)) {
            return dev
        }
        return prod
    }

    moneyMax = (s, currency = 0) => {
        return currency === 0 ? this.money.format(s) : this.moneyAlt.format(s)
    };

    telCommonCountryCodes = [
        43,
        39,
        32,
        371,
        387,
        382,
        385,
        212,
        420,
        31,
        45,
        47,
        372,
        48,
        358,
        351,
        33,
        7,
        49,
        421,
        350,
        386,
        44,
        34,
        30,
        46,
        36,
        41,
        353,
        90,
    ];

    isNumber = function (value) {
        return typeof value === 'number' &&
            isFinite(value) &&
            Math.floor(value) === value;
    };

    phoneNormalise(s) {
        if (this.isNumber(s) || !s)
            return s;

        s = s.replace(" ", "");
        if (s === "")
            return 0;
        s = s.replace(".", "").replace("-", "").replace("/", "");

        if (s.length < 5)
            return parseInt(s);

        if (s[0] === "+")
            s = s.substr(1);

        s = s.replace(/^0+/, "");

        if (!(this.telCommonCountryCodes.indexOf(parseInt(s.substr(0, 2))) > -1 || this.telCommonCountryCodes.indexOf(parseInt(s.substr(0, 3))) > -1)) {
            s = "43 " + s;
        } else {
            s = s.substr(0, 2) + " " + s.substr(2)
        }
        const cell = parseInt(s.substr(3, 3));
        let dist = 7;
        if (650 <= cell && cell <= 699) {
            dist = 6;
        }
        return "+" + s.substr(0, dist) + " " + s.substr(dist)
    };

    countries = [
        "AT", "DE", "CH", "IT",
        ...[
            "FR", "GB", "BE", "NL", "IE", "PL", "ES", "FI", "CZ", "SE", "NO", "HU", "SI", "SK", "SG", "DK", "EE", "LV", "LT", "MT", "PT", "GR", "RO", "BG", "HR",
            "CY", "LU",
        ].sort()
    ];

    competitionCategories = ["Wettkampf", "Hobby", "Masters"];
    competitionUnits = ["Zeit [min:s]", "Anzahl", "Länge [m / cm]", "Zeit [s]"];
    judgeFields = [
        "judgeW",
        "judgeM5",
        "judgeM7",
        "judgeChW",
        "judgeChM",
        "judgeChM7",
    ]
    WETTKAMPF_PLATTFORM="wettkampf.vereinfacht.at"
    TURNFEST_PLATTFORM="meldung.turnfest.at"
    OETB_PLATTFORM="wettkampf.oetb.at"
}

const Consts = new ConstsClass();


export default Consts;
